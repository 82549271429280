
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import React from 'react';
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Conditions from "./conditions";
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import avatar from '../../assets/images/user_avatar.svg'
import outlineStar from '../../assets/images/star-outline.svg'


import heartIcon from '../../../src/assets/images/heart.svg';
import heartFilledIcon from '../../../src/assets/images/heart-filled.svg';
import shareIcon from '../../../src/assets/images/share.svg';
import shareIconBlack from '../../../src/assets/images/share-black.svg';
import heartIconBlack from '../../../src/assets/images/heart-black.svg';
import heartFilledBlack from '../../../src/assets/images/heart-filled-black.svg';
import flagIconBlack from '../../../src/assets/images/flag-black.svg';
import copyIcon from '../../../src/assets/images/copy.svg';
import OfferupModal from '../Modals/OfferupModal';
import AskOffers from './AskOffers';
import MakeOffer from './MakeOffer';
import star from '../../assets/images/star-grey.svg'
import playStoreIcon from '../../assets/images/play-store.svg'
import appleIcon from '../../assets/images/apple.svg'
import QRCodeIcon from '../../assets/images/qr_code.svg'
import starGolden from '../../assets/images/star-golden.svg'

import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
  InstapaperShareButton,
  WhatsappShareButton,
} from "react-share";
import Footer from '../Footer';

const MySwal = withReactContent(Swal);

const ShowProduct = ({ currentUser }) => {
  const { id } = useParams();
  let savedProduct = currentUser && currentUser.saved_products && currentUser.saved_products.indexOf(parseInt(id)) !== -1;
  const [error, setError] = useState(null);
  const [product, setProduct] = useState(null);
  const [currImage, setCurrImage] = useState(null);
  const [saved, setIsSaved] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [ratingScore, setRatingScore] = useState(0);
  const [ratings, setRatings] = useState([]);
  // const { id } = useParams();
  const [content, setContent] = useState('');
  const [offerType, setOfferType] = useState('');
  const [shareModal, showShareModal] = useState(false);

  let showGobackBtn = false;
  let modalTitle = '';
  let goBackContent = '';
  let modalContent = '';

  // modalTitle = content.title;
  if (offerType === 'ask_offer') {
    modalTitle = "Send a Message";
    modalContent = <AskOffers product={product} onHide={hideModal} />
  } else if (offerType === 'make_offer') {
    if (product.firm_on_price) {
      modalTitle = "Review your offer";
    } else {
      modalTitle = "Send a Message";
    }
    modalContent = <MakeOffer product={product} onHide={hideModal} />
  }
  const getProduct = async (productId) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/products/${productId}`
    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json'
        }
      })
      const data = await response.json()
      if (!response.ok) {
        setError(data.message)
        throw data.error
      }
      setProduct(data)
      setRatingScore(parseFloat(data.rating_score));
      setRatings(data.ratings);
      setIsSaved(savedProduct);
      //setIsSaved(data.saved);
      setCurrImage(data.photos[0]);
      // navigator.geolocation.getCurrentPosition((position) => {

      // })

      var container = L.DomUtil.get('map');
      if (container != null) {
        container._leaflet_id = null;
      }
      let map = L.map('map').setView([data.lat, data.lng], 15);
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "© OpenStreetMap contributors",
        maxZoom: 18,
      }).addTo(map);
      var circle = L.circle([data.lat, data.lng], {
        color: 'none',
        fillColor: '#8dfa28',
        fillOpacity: 0.5,
        radius: 200
      }).addTo(map);

      // localStorage.setItem("token", response.headers.get("Authorization"))
      // setCurrUser(data)  
      // onHide();
    } catch (error) {
      //  setError(error)
    }
  }

  const copyProductLink = async (productId) => {
    try {
      await navigator.clipboard.writeText(`${process.env.REACT_APP_FRONTEND_URL}/product/${productId}`);
      MySwal.fire({
        title: 'Link Copied to clipboard!!!'
      })
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }


  const saveProduct = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/products/${product.id}/add-to-favourite`
    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        }
      })
      const data = await response.json()
      if (!response.ok) {
        setError(data.message)
        throw data.error
      }
      setIsSaved(data.status === 'added')
      MySwal.fire({
        title: 'Product added to saved list'
      })
    } catch (error) {
      //  setError(error)
    }
  }

  // const incrementProductViewCount=async (product_id)=> {
  //   const url=`${process.env.REACT_APP_BACKEND_URL}/products/${product_id}/increment-product-view-count`
  //   try{
  //       const response=await fetch(url, {
  //           method: "get",
  //           headers: {
  //               'content-type': 'application/json',
  //               'accept': 'application/json',
  //           }
  //       })
  //       const data=await response.json()
  //       if(!response.ok){
  //         setError(data.message)
  //         throw data.error
  //       }    
  //       setIsSaved(data.status === 'added')
  //   }catch(error){
  //     //  setError(error)
  //   }
  // }

  // async function askOffer() {
  //   setShowModal(true);
  // }

  function hideModal() {
    setShowModal(false);
    setContent('');
  }

  function handleSetContent(content) {
    setShowModal(true);
    setContent(content);
  }

  function showOfferupModal(type) {
    if (currentUser == null) {
      MySwal.fire({
        title: 'Please sign in to send message'
      });
      return;
    }
    if (currentUser.id === product.user_id) {
      MySwal.fire({
        title: 'You cannot send message to yourself'
      });
      return;
    }
    if (currentUser) {
      setOfferType(type);
      setShowModal(true)
    } else {
      MySwal.fire({
        title: 'Please sign in to send message'
      });
    }
  }

  useEffect(() => {
    getProduct(id);
    // incrementProductViewCount(id);
  }, [id]);

  return (
    <>
      {showModal &&
        <OfferupModal
          showGoBack={showGobackBtn}
          show={showModal}
          hide={hideModal}
          title={modalTitle}
          goBackContent={goBackContent}
          onChangeContent={handleSetContent}
        >
          {modalContent}
        </OfferupModal>
      }
      {shareModal &&
        <OfferupModal
          showGoBack={false}
          show={shareModal}
          hide={() => showShareModal(false)}
          title={"Share Product"}
        // goBackContent={}
        // onChangeContent={}
        // () => copyProductLink(product?.id)
        >
          <div>
            <FacebookShareButton onClick={() => showShareModal(false)} hashtag={`Check this product`} url={`${process.env.REACT_APP_FRONTEND_URL}/product/${id}`}>
              <div className='d-flex align-items-center gap-2'>
                <FacebookIcon size={32} round={true} />
                <span>Share on Facebook</span>
              </div>
            </FacebookShareButton>
            <br />
            <WhatsappShareButton className='mt-3' onClick={() => showShareModal(false)} url={`${process.env.REACT_APP_FRONTEND_URL}/product/${id}`}>
              <div className='d-flex align-items-center gap-2'>
                <WhatsappIcon size={32} round={true} />
                <span>Share on Whatsapp</span>
              </div>
            </WhatsappShareButton>
            <br />
            <EmailShareButton
              // onClick={() => showShareModal(false)}
              url={''}
              subject="please check this product"
              body={`${process.env.REACT_APP_FRONTEND_URL}/product/${id}`}
              className="Demo__some-network__share-button mt-2">
              <div className='d-flex align-items-center gap-2'>
                <EmailIcon size={32} round={true} />
                <span>Share in Email</span>
              </div>
            </EmailShareButton>
            <br />
            <div className='d-flex align-items-center gap-2 mt-2 cursor-pointer' onClick={() => { showShareModal(false); copyProductLink(product?.id) }}>
              <img src={copyIcon} />
              <span>Copy Link</span>
            </div>
          </div >

        </OfferupModal >
      }
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-md-8">
            <div className='d-flex gap-3'>
              <div className="d-flex align-items-center flex-column gap-3" style={{ overflow: 'auto' }}>
                {
                  product && product.photos.map(photo => {
                    let classes = ""
                    if (photo === currImage) {
                      classes += ' active-img '
                    } else {
                      classes += ' non-active-img '
                    }
                    return <img
                      key={photo}
                      className={classes}
                      src={photo}
                      alt={product?.title}
                      style={{ height: '100px', width: '100px', objectFit: 'contain' }}
                      onClick={() => setCurrImage(photo)}
                    />
                  })
                }
              </div>
              <div className="d-flex justify-content-center align-items-center" style={{ flex: 1, backgroundColor: '#FFF' }}>
                <img src={currImage} alt={product?.title} style={{ height: '500px', maxWidth: '100%', objectFit: 'contain' }} />
              </div>
            </div>
            <hr />
            <h3 style={{ fontWeight: '700' }}>Details</h3>
            <table>
              <tbody>
                {product?.size_type && <tr>
                  <td><strong>Size Type</strong></td>
                  <td style={{ paddingLeft: '20px' }}><span>{product?.size_type || "N/A"}</span></td>
                </tr>
                }
                {
                  product?.brand && <tr>
                    <td><strong>Brand</strong></td>
                    <td style={{ paddingLeft: '20px' }}><span>{product?.brand || "N/A"}</span></td>
                  </tr>
                }
              </tbody>
            </table>
            {/* <hr /> */}
            {product?.description && <div>
              <strong>Description</strong>
              <br />
              <span dangerouslySetInnerHTML={{ __html: product?.description }}></span>
            </div>
            }
            {/* <hr /> */}
            {/* <div className='d-flex align-items-center gap-4'>
              <div onClick={saveProduct} className='d-flex gap-1 align-items-center' style={{ backgroundColor: '#e4e4e4', padding: '3px 5px', borderRadius: '20px' }}>
                <img src={heartIconBlack} alt='heart' style={{ height: '12px', width: '12px' }} />
                <span style={{ fontSize: '12px' }}>Save</span>
              </div>
              <div className='d-flex gap-1 align-items-center' style={{ backgroundColor: '#e4e4e4', padding: '3px 5px', borderRadius: '20px' }}>
                <img src={flagIconBlack} alt='heart' style={{ height: '12px', width: '12px' }} />
                <span style={{ fontSize: '12px' }}>Report</span>
              </div>
              <div className='d-flex gap-1 align-items-center' style={{ backgroundColor: '#e4e4e4', padding: '3px 5px', borderRadius: '20px' }}>
                <img src={shareIconBlack} alt='heart' style={{ height: '12px', width: '12px' }} />
                <span style={{ fontSize: '12px' }}>Share</span>
              </div>
            </div> */}
            {/* <hr /> */}
          </div>
          <div className="col-12 col-md-4 mt-3 mt-md-0">
            <i><h2 style={{ fontWeight: '700' }}>{product?.title}</h2></i>
            <p><strong style={{ fontWeight: '800' }}>Price: <span className='text-dark-orange'>${product?.price}</span></strong></p>
            <div>
              <p>Posted {product?.time_posted} ago</p>
              <p>Condition: {Conditions[product?.condition]}</p>
              <p>{product?.category_name} - {product?.sub_category_name}</p>
            </div>
            {/* <button onClick={() => showOfferupModal('make_offer')} className='btn btn-outline-light border border-1 w-100 bg-orange rounded-pill position-relative text-white fw-medium'>Make offer</button> */}
            <button onClick={() => showOfferupModal('make_offer')} className='btn btn-outline-light border border-1 w-100 bg-orange position-relative text-white fw-medium p-2'>Make offer</button>
            <button onClick={() => showOfferupModal('ask_offer')} className="btn btn-outline-light w-100 text-orange border-orange position-relative mt-4 p-2" style={{ border: 'solid 1px #00a87e' }}>
              <span className="fw-medium">Ask</span>
            </button>
            <hr style={{ margin: '20px 0px' }} />
            <div className='row'>
              <div className='col-6'>
                <div className='d-flex justify-content-center'>
                  <div className='d-flex gap-2 align-items-center' style={{ 'cursor': 'pointer' }} onClick={saveProduct}>
                    <img src={saved ? heartFilledIcon : heartIcon} style={{ width: '24px', height: '24px' }} alt='heart icon' />
                    <span className='text-orange'>Save</span>
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <div className='d-flex justify-content-center'>
                  <div className='d-flex gap-2 align-items-center' style={{ 'cursor': 'pointer' }} onClick={() => showShareModal(true)}>
                    <img src={shareIcon} style={{ width: '24px', height: '24px' }} alt='share icon' />
                    <span className='text-orange'>Share</span>
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ margin: '20px 0px' }} />
            <Link to={`/user/${product?.user_id}`} style={{ textDecoration: 'none' }}>
              <div className='row'>
                <div className='d-flex align-items-center'>
                  <img src={product?.user_avatar_url ? product?.user_avatar_url : avatar} alt='avatar' style={{ height: '70px', width: '70px' }} />
                  <div className='ms-2 d-flex flex-column'>
                    <span className='fw-bold'>{product?.user}</span>
                    <span className='d-flex align-items-center gap-1'>
                      <img src={ratingScore >= 1 ? starGolden : star} alt='star' style={{ height: '20px', width: '20px' }} />
                      <img src={ratingScore >= 2 ? starGolden : star} alt='star' style={{ height: '20px', width: '20px' }} />
                      <img src={ratingScore >= 3 ? starGolden : star} alt='star' style={{ height: '20px', width: '20px' }} />
                      <img src={ratingScore >= 4 ? starGolden : star} alt='star' style={{ height: '20px', width: '20px' }} />
                      <img src={ratingScore >= 5 ? starGolden : star} alt='star' style={{ height: '20px', width: '20px' }} />
                      <span className='fs-6'>({ratings.length})</span>
                    </span>
                  </div>
                </div>
              </div>
            </Link>
            <div className='card product-shadow border border-1 p-3 mt-3'>
              <strong>Scan the QR code to get the app!</strong>
              <div className='mt-2 text-center'>
                <img src={QRCodeIcon} alt='qr_code' style={{ height: '100px', width: '100px' }} />
              </div>
              <div className='my-2'><p>OR Download the App</p></div>
              <div className='d-flex justify-content-center'>
                <button class="btn btn-primary bg-orange border-orange" data-bs-toggle="modal" data-bs-target="#exampleModal">Get the app</button>
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div id="map" style={{ height: '400px', width: '100%' }}></div>
          <p className='mt-2' style={{ color: 'rgb(152 149 149)', fontSize: '12px' }}>Map is approximate to keep seller's location private</p>
          <div >
            <h4 style={{ fontWeight: '800' }}>Similar Items</h4>
            <div className='row products-list'>
              {
                product && product.similar_products.map(sp => {
                  let [p_id, p_image, p_title, p_price] = sp
                  return <div className="product-item" key={p_id}>
                    <div style={{ height: 'calc(100% - 8px)' }}>
                      <Link to={`/product/${p_id}`} style={{ textDecoration: 'none' }}>
                        <div className="card product-shadow p-2 border-0" style={{ height: '100%' }}>
                          <div className="" style={{ position: 'relative', width: '100%', paddingTop: '100%' }}>
                            <img src={p_image} className="" alt={p_title} style={{ maxWidth: '100%', height: '100%', position: 'absolute', objectFit: 'contain', top: '0px' }} />
                          </div>
                          <div className="mx-2">
                            <p className="my-1 fs-5" style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textDecoration: 'none' }}>{p_title}</p>
                            <span className="fs-5 text-dark-orange" style={{ textDecoration: 'none' }}>${p_price}</span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                })
              }
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
};

export default ShowProduct;