
import { useEffect, useState, useRef } from "react";
import { redirect } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Footer from "../Footer";
import { ReactSortable } from "react-sortablejs";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { set } from "date-fns";

const MySwal = withReactContent(Swal);

const SellItem = () => {
  const zipRef = useRef();
  const latRef = useRef();
  const lngRef = useRef();
  const sizeRef = useRef();
  const [categories, setCategories] = useState([])
  const [sub_categories, setSubCategories] = useState([])
  const [product, setProduct] = useState({});
  const [photos, setPhotos] = useState([]);
  // const [currentLocation, setCurrentLocation] = useState(null);
  const [LatLang, setLatLng] = useState({})
  const [error, setError] = useState(null);
  const [address, setAddress] = useState(null);
  const [loader, showLoader] = useState(false);

  const [productImages, setProductImages] = useState([]);

  if (localStorage.getItem('token') === undefined || localStorage.getItem('token') === null) {
    // need to check the real use from database
    window.location.href = "/";
  }

  const getUser = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/get-user`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      return data;
    }
    catch (error) {
      return {};
    }
  }

  const getCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/categories`, {
        method: "get",
        headers: {
          "content-type": "application/json"
        }
      })

      if (!response.ok) throw Error
      const data = await response.json()
      setCategories(data)
    }

    catch (error) {
      setCategories([])
    }
  }

  const getSubCategories = async (event) => {
    let id = event.target.value;
    if (['Clothing, Shoes &amp; Accessories', 'Baby &amp; Kids', 'Sports &amp; Ourdoors'].indexOf(document.querySelector(`option[value='${id}']`).innerHTML) > -1) {
      sizeRef.current.classList.remove('d-none');
    } else {
      sizeRef.current.classList.add('d-none');
    }
    setData('category_id', id);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/sub_categories?category=` + id, {
        method: "get",
        headers: {
          "content-type": "application/json"
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setSubCategories(data.sub_categories)
    }
    catch (error) {
      setSubCategories([])
    }
  }

  function setData(key, value) {
    setProduct(p => {
      let updated_product = { ...p }
      updated_product[key] = value
      return updated_product;
    })
  }

  function setFiles(event) {
    // const chosenFiles = Array.prototype.slice.call(event.target.files)
    // const uploaded = [];
    // chosenFiles.some((file) => {
    //     uploaded.push(file);
    // })

    if (event.target.files.length > 5) {
      event.target.value = null;
      MySwal.fire({
        title: 'Please select 5 or less images'
      })
      return;
    }
    setProduct(p => {
      let updated_product = { ...p }
      updated_product['photos'] = [...event.target.files]
      setProductImages([...event.target.files]);
      return updated_product;
    })
    console.log(product)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    var form_data = new FormData();
    let validProduct = true;
    for (let key of ['title', 'photos', 'category_id', 'condition', 'brand', 'price']) {
      if (!product[key]) {
        MySwal.fire({
          title: `Please set value for ${key}`,
        })
        validProduct = false;
        break;
      }
    }
    if (!product['lat'] || !product['lng']) {
      MySwal.fire({
        title: `Please set Location`,
      })
      validProduct = false;
    }
    if (!validProduct) {
      return;
    }
    for (var key in product) {
      if (key === "photos") {
        let files = product[key]
        for (var f of files) {
          form_data.append(`product[${key}][]`, f);
        }
      } else {
        form_data.append(`product[${key}]`, product[key]);
      }
    }
    let user = await getUser();
    form_data.append("product[user_id]", user.id);

    // for(let photo of [...product.photos]){
    //   form_data.append("product[photos][]", photo)
    // }

    // for (var pair of form_data.entries()) {
    // }

    // const formData = new FormData(e.target);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/products`, {
        method: "post",
        headers: {
          "authorization": localStorage.getItem("token")
        },
        body: form_data
      })
      if (!response.ok) throw Error
      window.location.href = "/";
    }
    catch (error) {
    }
  }

  useEffect(() => {
    getCategories()
  }, [])

  const getLocationByZipCode = () => {
    showLoader(true);
    let zip = zipRef.current.value;
    const fetchNearestAddress = async (zip) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/locations/get-location-by-zip-code?zip=${zip}`)
        if (!response.ok) {
          throw new Error('Failed to fetch nearest address');
        }
        const data = await response.json();
        if (!data.cidade) {
          MySwal.fire({
            title: 'Could not get location by zip code, please check details and try again'
          })
          showLoader(false);
          return;
        }
        // let  data = {
        //   latitude: 74.2757747,
        //   longitude: 31.4616912
        // }
        setAddress(data);
        setData('lat', data.latitude);
        setData('lng', data.longitude);
        showLoader(false);
      } catch (error) {
        setError(error.message);
      }
    }

    fetchNearestAddress(zip);
  }

  const getLatLngLocation = () => {
    const fetchNearestAddress = async (latitude, longitude) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/locations/get-location-by-lat-lng?lat=${latitude}&lng=${longitude}`)
        if (!response.ok) {
          throw new Error('Failed to fetch nearest address');
        }
        const data = await response.json();
        if (!data.cidade) {
          MySwal.fire({
            title: 'Could not get location, please refresh and try again'
          })
          showLoader(false);
          return;
        }
        setAddress(data);
        // let  data = {
        //   latitude: 74.2757747,
        //   longitude: 31.4616912
        // }
        setData('lat', data.latitude);
        setData('lng', data.longitude);
        setData('city', data.cidade.nome)
        showLoader(false);
      } catch (error) {
        setError(error.message);
      }
    }

    const getCurrentLocation = () => {
      showLoader(true)
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            // setCurrentLocation({ latitude, longitude });
            fetchNearestAddress(latitude, longitude);
          },
          (error) => {
            setError(error.message);
          }
        );
      } else {
        setError('Geolocation is not supported by this browser.');
      }
    }

    getCurrentLocation();
  }

  const orderChanged = (data) => {
    setProductImages(data);
    setProduct(p => {
      let updated_product = { ...p }
      updated_product['photos'] = [...data]
      return updated_product;
    })
  }


  return (
    <>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="container mt-5 mt-lg-2">
          <div className="row">
            <div className="col-12 col-md-6 offset-0 offset-md-3">
              <h1>Sell Item</h1>
              <div className="card">
                <div className="card-header">
                  <h2>Step 1: Post</h2>
                </div>
                <div className="card-body">
                  <div className="form-group mt-2">
                    <label className="fw-bold">Title</label>
                    <input type="text" className="form-control" name="product[title]" onChange={(event) => setData('title', event.target.value)} />
                  </div>
                  <div className="form-group mt-2">
                    <label className="fw-bold">Description</label>
                    <ReactQuill theme="snow" name="product[description]" onChange={(data) => setData('description', data)} />
                  </div>
                  <div className="form-group">
                    <label className="fw-bold">Select Photos <span style={{ color: 'red', fontStyle: 'italic' }}>(Maximum 5 photos)</span></label>
                    <input type="file" className="form-control" name="product[photos]" onChange={(event) => setFiles(event)} multiple />
                  </div>
                  <div id="product-photos" >
                  <ReactSortable list={productImages} setList={(data) => orderChanged(data)} animation="200" easing="ease-out" style={{ display: 'flex', gap: '10px', margin: '10px'}}>
                    {
                      productImages.length > 0 && productImages.map(function(photo, indx) {
                        const imageUrl = URL.createObjectURL(photo);
                        return <div key={indx}>
                          <img src={imageUrl} height={100} width={100} />
                        </div>
                      })
                    }
                    </ReactSortable>
                  </div>
                </div>
              </div>
              <div className="card mt-2">
                <div className="card-header">
                  <h2>Step 2: Details</h2>
                </div>
                <div className="card-body">
                  <div className="form-group mt-2">
                    <label className="fw-bold">Category</label>
                    <select className="form-control" name="product[category_id]" onChange={getSubCategories}>
                      <option value="">Select Category</option>
                      {
                        categories.map(category => <option value={category.id} key={category.id}>{category.name}</option>)
                      }
                    </select>
                  </div>
                  <div className="form-group mt-2">
                    <label className="fw-bold">Sub Category</label>
                    <select className="form-control" name="product[sub_category_id]" onChange={(event) => setData('sub_category_id', event.target.value)}>
                      <option value="">Select Sub Category</option>
                      {
                        sub_categories.map(category => <option value={category.id} key={category.id}>{category.name}</option>)
                      }
                    </select>
                  </div>
                  <div className="form-group mt-2">
                    <label className="fw-bold">Condition</label>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="product[condition]" id="flexRadioDefault1" value="new" onChange={(event) => setData('condition', event.target.value)} />
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                        New (never used)
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="product[condition]" id="flexRadioDefault2" value="reconditioned" onChange={(event) => setData('condition', event.target.value)} />
                      <label className="form-check-label" htmlFor="flexRadioDefault2">
                        Reconditioned/Certified
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="product[condition]" id="flexRadioDefault3" value="open_box" onChange={(event) => setData('condition', event.target.value)} />
                      <label className="form-check-label" htmlFor="flexRadioDefault3">
                        Open Box (never used)
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="product[condition]" id="flexRadioDefault4" value="used" onChange={(event) => setData('condition', event.target.value)} />
                      <label className="form-check-label" htmlFor="flexRadioDefault4">
                        Used (normal wear)
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="product[condition]" id="flexRadioDefault5" value="other" onChange={(event) => setData('condition', event.target.value)} />
                      <label className="form-check-label" htmlFor="flexRadioDefault5">
                        Other
                      </label>
                    </div>
                  </div>
                  <div className="form-group mt-2">
                    <label className="fw-bold">Brand</label>
                    <input type="text" className="form-control" name="product[brand]" onChange={(event) => setData('brand', event.target.value)} />
                  </div>
                  <div className="form-group mt-2" ref={sizeRef}>
                    <label className="fw-bold">Size Type</label>
                    <input type="text" className="form-control" name="product[size_type]" onChange={(event) => setData('size_type', event.target.value)} />
                  </div>
                </div>
              </div>

              <div className="card mt-2">
                <div className="card-header">
                  <h2>Step 3: Price</h2>
                </div>
                <div className="card-body">
                  <div className="form-group mt-2">
                    <label className="fw-bold">Price</label>
                    <input type="number" className="form-control" name="product[price]" placeholder="$0" onChange={(event) => setData('price', event.target.value)} />
                  </div>
                </div>
              </div>
              <div className="card mt-2">
                <div className="card-header">
                  <h2>Step 4: Location</h2>
                  <input type="hidden" ref={latRef} name="product[lat]" value={address?.latitude} />
                  <input type="hidden" ref={lngRef} name="product[lng]" value={address?.longitude} />
                </div>
                <div className="card-body">
                  <div class="d-flex align-items-center justify-content-center">
                    <button type="button" class="btn btn-rounded border-orange text-orange ms-2" style={{ 'borderRadius': '20px' }} onClick={getLatLngLocation}>Get Current Location</button>
                  </div>
                  <div class="d-flex align-items-center justify-content-center mt-2">
                    <strong>OR</strong>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <div className="form-group mt-2">
                      <label className="fw-bold">Search By Zip Code</label>
                      <div className="d-flex gap-2 align-items-center">
                        <input type="text" ref={zipRef} className="form-control" name="product[zipcode]" placeholder="Zip Code" />
                        <button type="button" className="btn btn-success bg-orange border-orange" onClick={getLocationByZipCode}>Get</button>
                      </div>
                    </div>
                  </div>
                  <div>
                    {loader &&
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    }
                  </div>
                  <hr />
                  {address && <div><strong>Your Location: <span style={{ color: 'red' }}>{address.cidade.nome}</span></strong></div>}
                </div>
              </div>
              <button className="btn btn-success bg-orange border-orange mt-2">Submit</button>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </>
  )
}
export default SellItem