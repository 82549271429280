
import { useState,useEffect, useContext, useRef } from "react"


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import SearchIcon from '../assets/images/search.svg'
import { Link } from 'react-router-dom'



// import logo  from '../assets/images/offerup.svg'
import logo  from '../assets/images/buscalok2.png'
import downArrow from '../assets/images/down-arrow.svg'
import avatar from '../assets/images/user_avatar.svg'
import Logout from "./Logout";
import heartIcon from '../../src/assets/images/heart2.svg';
import heartIconBlack from '../../src/assets/images/heart-black.svg';
import heartIconWhite from '../../src/assets/images/heart-white.svg';
// import InboxIcon from '../../src/assets/images/inbox.svg';
import InboxIcon from '../../src/assets/images/chat.svg';
import ProfileIcon from '../../src/assets/images/profile.svg';
import tagIcon from '../../src/assets/images/tag.svg';
import tagIconWhite from '../../src/assets/images/tag-white.svg';
import ThemeContext from "../context/ThemeContext";
import Dropdown from 'react-bootstrap/Dropdown';
import userAvatar from './../assets/images/user_avatar.svg';
// import LocationIcon from './../assets/images/location.svg'
import LocationIcon from './../assets/images/location2.svg'
import RightArrow from '../../src/assets/images/right-arrow.svg'
import cable from "../cable";
import OfferupModal from "./Modals/OfferupModal";
import Parser from 'html-react-parser';
import { useSearchParams } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import NavbarCategories from "./NavbarCategories";

const MySwal = withReactContent(Swal);

const OfferupNavbar=({currUser, onClickLogin, onClickSignUp, categories, cityName, setCity, lat, lng, setLat, setLng, searchValue, switchLanguage, setProducts})=>{
	console.log("City naame = ", cityName);
	const dropdownRef = useRef();
	const zipRef = useRef();
  const latRef = useRef();
  const lngRef = useRef();
	// const sliderRef = useRef();
	const tooltipRef = useRef();
	const [address, setAddress] = useState(null);
	const [unread, setUnread] = useState(false);
	// const [newCityName, setNewCityName] = useState(cityName);
	const [productsFilter, setProductsFilter] = useState(false);
	const [locationModal, setLocationModal] = useState(false);
 	// const [messages, setMessages] = useState([]);
	const [inboxes, setInboxes] = useState([]);
	const [notifications, setNotifications] = useState([]);
	const [messageLoader, setMessageLoader] = useState(true);
	const [notificationLoader, setNotificationLoader] = useState(true);
	const [loader, showLoader] = useState(false);
	const [error, setError] = useState(null);
	const [params, setParams] = useSearchParams();
	const searchRef = useRef();
	const [slider, setSlider] = useState(30);
	const [locPopup, setLocPopup] = useState(localStorage.getItem('loc-popup') === 'ok');

	useEffect(() => {
		setUnread(currUser?.has_unread);
		const channel = cable.subscriptions.create(
      { channel: 'NotificationsChannel', user_id: currUser?.id },
      {
        received: (message) => {
					setUnread(true);
          // setMessages(prevMessages => [...prevMessages, message]);
        }
      }
    );
    return () => {
      channel.unsubscribe();
    };
	}, [currUser])

	function showLocationProducts() {
		setProductsFilter(true);
	}

	function hideModal() {
		setProductsFilter(false);
	}

	function showModal() {
		setProductsFilter(true);
	}

	async function fetchMessages(event){
		event.preventDefault();
		if(!document.querySelector("#inbox").classList.contains("show")) {
			try {
        const response=await fetch(`${process.env.REACT_APP_BACKEND_URL}/inboxes/get-inboxes`, {
            method: "get",
            headers: {
                "content-type": "application/json",
                "authorization": localStorage.getItem("token")
            }
        })
        if(!response.ok) throw Error
        const data=await response.json()
				setMessageLoader(false);
        setInboxes(data.inboxes)
				setNotifications(data.notifications);
				let has_unread = false;
				data.data.forEach(function(inbox){
					if(inbox.has_unread){
						has_unread = true;
					}
				})
				setUnread(has_unread);
				// setUnread(false);
        if(data.avatar_url) {
          // setImageUrl(data.avatar_url)
        }
			}
			catch(error){
				// setUser(null);
			}

			// try {
      //   const response=await fetch(`${process.env.REACT_APP_BACKEND_URL}/messages`, {
      //       method: "get",
      //       headers: {
      //           "content-type": "application/json",
      //           "authorization": localStorage.getItem("token")
      //       }
      //   })
      //   if(!response.ok) throw Error
      //   const data=await response.json()
			// 	setMessageLoader(false);
      //   setMessages(data)
			// 	setUnread(false);
      //   if(data.avatar_url) {
      //     // setImageUrl(data.avatar_url)
      //   }
			// }
			// catch(error){
			// 	// setUser(null);
			// }
		}
	}

	const hideDropdown = (inbox_id) => {
		let has_unread = false;
		inboxes.filter(inbox => inbox.id !== inbox_id).forEach(function(inbox){
			if(inbox.has_unread) {
				has_unread = true;
			}
		})
		setUnread(has_unread);
		setTimeout(function() {
			dropdownRef.current.classList.toggle("show");
			if(document.getElementById("dropdown-autoclose-outside")) {
				document.getElementById("dropdown-autoclose-outside").classList.toggle("show");
			}
			if(document.getElementById("dropdown-menu")) {
				document.getElementById("dropdown-menu").classList.toggle("show");
			}
			if(document.getElementById("dropdown-menu2")) {
				document.getElementById("dropdown-menu2").classList.toggle("show");
			}
		}, 100)
	}

	function changeLocation() {
		setAddress('');
		setProductsFilter(false);
		setLocationModal(true);
	}

	function showProductsFilter() {
		setLocationModal(false);
		setProductsFilter(true);
	}

	const getLatLngLocation = () => {
    const fetchNearestAddress = async (latitude, longitude) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/locations/get-location-by-lat-lng?lat=${latitude}&lng=${longitude}`)
        if (!response.ok) {
          throw new Error('Failed to fetch nearest address');
        }
        const data = await response.json();
        if (!data.cidade) {
          MySwal.fire({
            title: 'Could not get location, please refresh and try again'
          })
          showLoader(false);
          return;
        }
        setAddress(data);
				localStorage.setItem('lat', data.latitude);
				localStorage.setItem('lng', data.longitude);
				setLat(data.latitude);
				setLng(data.longitude);
				setCity(data.cidade.nome)
        // let  data = {
        //   latitude: 74.2757747,
        //   longitude: 31.4616912
        // }
        //setData('lat', data.latitude);
        //setData('lng', data.longitude);
        //setData('city', data.cidade.nome)
        showLoader(false);
      } catch (error) {
        setError(error.message);
      }
    }

    const getCurrentLocation = () => {
      showLoader(true)
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            // setCurrentLocation({ latitude, longitude });
            fetchNearestAddress(latitude, longitude);
          },
          (error) => {
            setError(error.message);
          }
        );
      } else {
        setError('Geolocation is not supported by this browser.');
      }
    }

    getCurrentLocation();
  }

	const getLocationByZipCode = () => {
    showLoader(true);
    let zip = zipRef.current.value;
    const fetchNearestAddress = async (zip) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/locations/get-location-by-zip-code?zip=${zip}`)
        if (!response.ok) {
          throw new Error('Failed to fetch nearest address');
        }
        const data = await response.json();
        if (!data.cidade) {
          MySwal.fire({
            title: 'Could not get location by zip code, please check details and try again'
          })
          showLoader(false);
          return;
        }
        // let  data = {
        //   latitude: 74.2757747,
        //   longitude: 31.4616912
        // }
        setAddress(data);
				localStorage.setItem('lat', data.latitude);
				localStorage.setItem('lng', data.longitude);
				setLat(data.latitude);
				setLng(data.longitude);
				setCity(data.cidade.nome)
        //setData('lat', data.latitude);
        //setData('lng', data.longitude);
        showLoader(false);
      } catch (error) {
        setError(error.message);
      }
    }

    fetchNearestAddress(zip);
  }

	// function updateTooltip(value) {
	// 	tooltipRef.current.style.display = 'block';
	// 	tooltipRef.current.textContent = value + ' miles';
		
	// 	// Calculate position
	// 	const range = sliderRef.current.max - sliderRef.current.min;
	// 	const percentage = (value - sliderRef.current.min) / range;
	// 	const sliderWidth = sliderRef.current.offsetWidth;
	// 	const tooltipWidth = tooltipRef.current.offsetWidth;
	// 	const thumbWidth = 16; // Approximate thumb width
		
	// 	// Position tooltip, accounting for thumb width
	// 	const position = percentage * (sliderWidth - thumbWidth) + (thumbWidth / 2);
	// 	tooltipRef.current.style.left = `${position}px`;
  // }

	async function seeListings() {
		localStorage.setItem('distance', slider)
		try {
      let queryStr = `?lat=${lat}&lng=${lng}&distance=${slider}`;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/products${queryStr}`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          // "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
			setProducts(data.products)
			setProductsFilter(false);
    }
    catch (error) {
      setProducts([])
    }
	}

	function goToRoot() {
		window.location.href = "/";
	}

	function submitSearchForm(event) {
		event.preventDefault();
		let cParams = []
    if (params.get("min")) {
      cParams.push({ key: "min", value: params.get("min") })
    }
		if (params.get("max")) {
      cParams.push({ key: "max", value: params.get("max") })
    }
		if (params.get("condition")) {
      cParams.push({ key: "condition", value: params.get("condition") })
    }

		if (params.get("category_id")) {
      cParams.push({ key: "category_id", value: params.get("category_id") })
    }

		if (params.get("sub_category_id")) {
      cParams.push({ key: "sub_category_id", value: params.get("sub_category_id") })
    }

		if(searchRef.current.value !== '') {
			cParams.push({ key: "search", value: searchRef.current.value })
		}
		
		// let paramsObj = {};
    // cParams.forEach(x => paramsObj[x.key] = x.value)
    // setParams(paramsObj)

    // window.location.reload();
		let paramsStr = [];
		cParams.forEach(x => paramsStr.push(`${x.key}=${x.value}`))
		window.location.href = `/?${paramsStr.join('&')}`
	}

	function openNav() {
		document.getElementById("mySidenav").style.width = "100%";
	}

	function closeNav() {
		document.getElementById("mySidenav").style.width = "0";
	}

	const setLocationPopup = () => {
		localStorage.setItem('loc-popup', 'ok');
		setLocPopup(true);
	}

	const {theme, setTheme} = useContext(ThemeContext);
  return(
		<>
			{productsFilter &&
        <OfferupModal
          showGoBack={false}
          show={showModal}
          hide={(hideModal)}
          title={"Location"}
          goBackContent={''}
          // onChangeContent={handleSetContent}
        >
          <div>
						<strong>Delivery Method</strong>
						<br />
						<input type="radio" checked="checked" />
						<label>Local</label>

						<hr />

						<strong>Location</strong>
						<div className="d-flex align-items-center justify-content-between cursor-pointer" onClick={changeLocation}>
							<span>{cityName || "N/A"}</span>
							<img src={RightArrow} alt="right arrow" style={{height: '20px', width: '20px'}} />
						</div>

						<hr />

						<strong>Distance (miles)</strong>
							<div className="slider-container">
								<input
											type="range"
											min="10"
											max="50"
											step="10"
											// value={20}
											defaultValue={localStorage.getItem('distance') || slider}
											className="slider"
											onInput={(e) => setSlider(e.target.value)}
											// onMouseEnter={() => updateTooltip(sliderRef.current.value)}
											// onFocus={() => updateTooltip(sliderRef.current.value)}
											// onMouseLeave={() => {
											// 	if (document.activeElement !== sliderRef.current) {
											// 		tooltipRef.current.style.display = 'none';
											// 	}
											// }}
											// onBlur={() => {
											// 	if (!sliderRef.current.matches(':hover')) {
											// 		tooltipRef.current.style.display = 'none';
											// 	}
											// }}
										/>
								<div className="tooltip" ref={tooltipRef}>20 miles</div>
								<div className="labels">
									<div className="label">10</div>
									<div className="label">20</div>
									<div className="label">30</div>
									<div className="label">40</div>
									<div className="label">Max</div>
								</div>
							</div>
							<button className="btn btn-outline-light border border-1 w-100 bg-orange position-relative text-white fw-medium p-2 mt-2" onClick={seeListings}>See Listings</button>
					</div>
        </OfferupModal>
      }
			{locationModal && 
				<OfferupModal
          showGoBack={true}
          show={showModal}
          hide={hideModal}
          title={"Change Location"}
          goBackContent={''}
					closeBtn={false}
          onChangeContent={showProductsFilter}
        >
          <div className="card-header">
						<input type="hidden" ref={latRef} value={lat} />
						<input type="hidden" ref={lngRef} value={lng} />
					</div>
					<div className="card-body">
						<div class="d-flex align-items-center justify-content-center">
							<button type="button" class="btn btn-rounded ms-2 border-orange text-orange" style={{ 'borderRadius': '20px' }} onClick={getLatLngLocation}>Get Current Location</button>
						</div>
						<div class="d-flex align-items-center justify-content-center mt-2">
							<strong>OR</strong>
						</div>
						<div class="d-flex align-items-center justify-content-center">
							<div className="form-group mt-2">
								<label className="fw-bold">Search By Zip Code</label>
								<div className="d-flex gap-2 align-items-center">
									<input type="text" ref={zipRef} className="form-control" name="product[zipcode]" placeholder="Zip Code" />
									<button type="button" className="btn btn-success bg-orange border-orange" onClick={getLocationByZipCode}>Get</button>
								</div>
							</div>
						</div>
						<div>
							{loader &&
								<div class="spinner-border" role="status">
									<span class="visually-hidden">Loading...</span>
								</div>
							}
						</div>
						<hr />
						{address && <div><strong>Location updated to: <span style={{ color: 'red' }}>{address.cidade.nome}</span></strong></div>}
					</div>
        </OfferupModal>
			}
			<div id="mySidenav" class="sidenav">
				<a href="javascript:void(0)" class="closebtn" onClick={closeNav}>&times;</a>
				<div>
					<div>
						<div className={`navbar-text w-100 me-3 p-0 ${currUser ? '' : 'mt-2'}`}>
							<ul className={`navbar-nav me-auto mb-2 mb-lg-0 ${currUser ? 'justify-content-between' : 'justify-content-end'}` } style={{display: 'flex', alignItems: 'center'}}>
								{ currUser ?
									<>
									<div className="d-flex align-items-center gap-1">
										<button className="btn btn-dark" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{border: 'none', color: 'white', borderRadius: '10px', padding: '5px 15px'}}>Get the App</button>
										<span className="nav-item">
											<Link to="/sell-item" onClick={closeNav}>
												<button className="btn btn-dark" style={{border: 'none', color: 'white', borderRadius: '10px', padding: '5px 15px'}}>Sell Item</button>
											</Link>
										</span>
									</div>
										<div className="d-flex align-items-center gap-2 mt-3">
											<Dropdown ref={dropdownRef} className="d-inline mx-2" autoclose="outside" id="inbox" onClick={fetchMessages}>
												<Dropdown.Toggle id="dropdown-autoclose-outside">
													<div className="d-flex align-items-end" autoclose="outside">
														<span className="nav-item">
															<div to="" className="mx-2 d-flex flex-column cursor-pointer pointer align-items-center" style={{position: 'relative'}}>
																<div className="d-flex" style={{gap: '4px'}}>
																	<SVG alt="inbox icon" src={theme === "dark" ? InboxIcon : InboxIcon} className="fill-orange"  style={{height: '25px', width: '25px'}}/>
																	{ unread &&
																		<div style={{height: '10px', width: '10px', borderRadius: '50%', background: 'red', position: 'absolute', right: '-5px'}}></div>
																	}
																</div>
																<span style={{fontSize: '14px', color: 'orange'}}>Chats</span>
															</div>
														</span>
														{/* <img src={downArrow} style={{height: '16px', width: '16px'}} alt="down-arrow" /> */}
													</div>
												</Dropdown.Toggle>

												<Dropdown.Menu id="dropdown-menu2" align="end" style={{width: '300px',right: 0, left: 'auto', transform: 'none !important'}}>
													<ul class="nav nav-pills mb-3 d-flex justify-content-around" id="pills-tab" role="tablist">
														<li class="nav-item" role="presentation">
															<div class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Messages</div>
														</li>
														
														<li class="nav-item" role="presentation">
															<div class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Notifications</div>
														</li>
													</ul>
													<div class="tab-content" id="pills-tabContent">
														<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
															{
																messageLoader && <div class="spinner-border" role="status">
																	<span class="visually-hidden">Loading...</span>
																</div>
															}
															{
																inboxes.length === 0 ? <span>No Messages</span> : <div>
																	{
																		inboxes && inboxes.map(function(inbox) {
																			return <Link onClick={()=> { hideDropdown(inbox.id);closeNav()}} to={`/inbox/${inbox.id}/messages`} style={{textDecoration: 'none'}} className="d-flex justify-content-between align-items-start gap-2 border-bottom p-2 hover-bg-gray" key={inbox.id}>
																				<img style={{height: '50px', width: '50px', borderRadius: '50%'}} src={inbox.user_avatar_url ? inbox.user_avatar_url : userAvatar} alt="user avatar" />
																				<div style={{flex: 1}}>
																					<div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
																						<strong>{inbox.user_name}</strong>
																						{ inbox.has_unread && <div style={{height: '10px', width: '10px', borderRadius: '50%', background: 'red'}}></div>}
																					</div>
																					<span>{Parser(inbox.message)}</span>
																					<br />
																					<span style={{color: 'gray', fontSize: '13px'}}>{inbox.message_created_at}</span>
																				</div>
																				<img style={{height: '50px', width: '50px'}} src={inbox.product_image} alt="product img" />
																			</Link>
																		})
																	}
																	{<Link onClick={closeNav} to={"/inbox"} style={{textDecoration: 'none'}}><strong className="text-orange">View all messages</strong></Link> }
																</div>
															}
														</div>
														<div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0">
															{ messageLoader && <div class="spinner-border" role="status">
																	<span class="visually-hidden">Loading...</span>
																</div>
															}
															{
																notifications.length === 0 ? <span>No Notifications</span> : <div>
																	{
																		notifications && notifications.map(function(notification) {
																			return <Link onClick={()=> hideDropdown(notification.id)} to={`/inbox/${notification.id}/messages`} style={{textDecoration: 'none'}} className="d-flex justify-content-between align-items-start gap-2 border-bottom p-2 hover-bg-gray" key={notification.id}>
																				<img style={{height: '50px', width: '50px', borderRadius: '50%'}} src={userAvatar} alt="user avatar" />
																				<div style={{flex: 1}}>
																					<div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
																						<strong>{notification.title}</strong>
																						{/* { inbox.has_unread && <div style={{height: '10px', width: '10px', borderRadius: '50%', background: 'red'}}></div>} */}
																					</div>
																					<span>{Parser(notification.content)}</span>
																					<br />
																					{/* <span style={{color: 'gray', fontSize: '13px'}}>{inbox.message_created_at}</span> */}
																				</div>
																				{/* <img style={{height: '50px', width: '50px'}} src={inbox.product_image} alt="product img" /> */}
																			</Link>
																		})
																	}
																	{<Link to={"/inbox"} onClick={closeNav} style={{textDecoration: 'none'}}><strong className="text-orange">View all notifications</strong></Link> }
																</div>
															}
														</div>
													</div>
													{/* <Dropdown.Item href="#">Menu Item</Dropdown.Item> */}
													{/* <Dropdown.Item href="#">Menu Item</Dropdown.Item> */}
													{/* <Dropdown.Item href="#">Menu Item</Dropdown.Item> */}
												</Dropdown.Menu>
											</Dropdown>
											<span className="nav-item">
												<Link to="/saved_products" onClick={closeNav} className="mx-2 d-flex flex-column cursor-pointer pointer align-items-center" style={{textDecoration: 'none'}}>
													<SVG src={heartIcon} className="fill-orange"  style={{height: '25px', width: '25px'}}/>
													<span style={{fontSize: '14px', color: 'orange'}}>Favorites</span>
												</Link>
											</span>
											<span className="nav-item">
												<Link to="/my-items" onClick={closeNav} className="mx-2 d-flex flex-column cursor-pointer pointer align-items-center"  style={{textDecoration: 'none'}}>
													<SVG src={theme === "dark" ? tagIconWhite : tagIcon} className="fill-orange"  style={{height: '25px', width: '25px'}}/>
													<span style={{fontSize: '14px', color: 'orange'}}>My Items</span>
												</Link>
											</span>
											<div className="dropdown">
												<div className="d-flex align-items-center flex-column" role="button" data-bs-toggle="dropdown" aria-expanded="false">
													<SVG src={ProfileIcon} className="fill-orange" style={{height: '30px', width: '30px'}}/>
													<span style={{fontSize: '14px', color: 'orange'}}>Profile</span>
													{/* <img src={downArrow} style={{height: '16px', width: '16px'}}/> */}
												</div>
												<div className="dropdown-menu me-2 pull-right" style={{width: '288px',right: 0, left: 'auto'}}>
													<li>
														<div className="dropdown-item">
															<Link onClick={closeNav} to={`/user/${currUser?.id}`} style={{textDecoration: 'underline', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
																<div href="#" className="d-flex flex-column flex-lg-row align-items-center gap-2 text-decoration-none">
																	<div>
																		<img src={currUser.avatar_url ? currUser.avatar_url : avatar} style={{height: '60px', width: '60px'}}/>
																	</div>
																	<div className="d-flex flex-column ms-2">
																		<strong style={{fontSize: '13px'}}>{currUser.email}</strong>
																		<span>View public profile</span>
																	</div>
																</div>
															</Link>
														</div>
													</li>
													<li>
														<div className="nav-item">
														{
															currUser.is_admin &&
															<Link onClick={closeNav} to="/admin/products">
																<button style={{border: 'none', color: 'white', borderRadius: '10px', padding: '5px 15px', background: 'red'}}>Admin</button>
															</Link>
														}
														</div>
													</li>
													<li>
														<Link onClick={closeNav} to="/account-settings" className="dropdown-item">Account Settings</Link>
													</li>
													<li>
														<Logout onClick={closeNav} /> 
														{/* <a href="#" className="dropdown-item">Account Settings</a> */}
														{/* <%#= link_to "Log out", destroy_user_session_path, data: { turbo_method: :delete }, class: 'dropdown-item' %> */}
													</li>
												</div>
											</div>
										</div>
										{/* <span className="nav-item">
											<a href="#" className="nav-link">{currUser.email}</a>
										</span> */}
									</>
											: (
												<div className="d-flex gap-2">
													<button className="btn btn-dark" style={{border: 'none', color: 'white', borderRadius: '10px', padding: '5px 15px'}} onClick={onClickSignUp}>Singup</button>
													<button className="btn btn-dark" style={{border: 'none', color: 'white', borderRadius: '10px', padding: '5px 15px'}} onClick={onClickLogin}>Login</button>
												</div>
									)
								}
							</ul>
						</div>
						<NavbarCategories categories={categories} />
					</div>
				</div>
			</div>
			<nav className={`navbar navbar-expand-lg p-0 w-100 pt-2 ${theme === 'dark' ? 'bg-dark text-light' : ''}`} id="navbar" data-bs-theme={theme} style={{display: 'inline-block', backgroundColor: "#E89122"}}>
				<div className="navbar-content gap-4">
					<div className="text-center d-flex justify-content-end ms-2 first-child">
						<div className="navbar-brand cursor-pointer p-0 d-flex justify-content-end m-0" onClick={goToRoot}>
							<img src={logo} alt="logo" style={{height: '46px', width: '172px'}} />
						</div>
					</div>
					<div style={{position: 'relative', marginTop: '3px'}} className="second-child">
						<form onSubmit={submitSearchForm} style={{display: 'flex', flexDirection: 'column', width: '100%', gap: 8}} className="navbar-nav me-auto justify-between">
							<div className="d-flex" style={{width: '100%'}}>
								<input type="text" placeholder="Search" ref={searchRef} className="search-input" name="search" defaultValue={searchValue} style={{backgroundColor: "#FFF", border: "#FFF"}} />
								<button className="search-btn" style={{backgroundColor: "#FFF", border: 'none'}}>
									<img src={SearchIcon} alt="Search" style={{height: '32px', width: '32x'}}/>
								</button>
							</div>
						</form>
						<div className="d-flex align-items-center city-name gap-1" style={{position: 'absolute'}}>
							<img src={LocationIcon} onClick={showModal} />
							<strong className="text-white underline" onClick={showModal}>Deliver in: {cityName || "N/A"}</strong>
							<div style={{position: 'absolute'}}>
								{
									!locPopup && <div><div className="up-arrow" style={{position: 'absolute'}}></div>
									<div className="product-shadow" style={{position: 'absolute', backgroundColor: 'white', top: '20px', minWidth: '200px', zIndex: 9999, padding: '20px', borderRadius: '5px'}}>
										<p>You can change the location here...</p>
										<button className="btn btn-outline-light text-orange border-orange" onClick={setLocationPopup}>Got It</button>
									</div></div>
								}
							</div>
						</div>
					</div>
					<div className="third-child">
						<button className="navbar-toggler" type="button" onClick={openNav}>
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="collapse navbar-collapse" id="navbarText">
							<div className={`navbar-text w-100 me-3 p-0 ${currUser ? '' : 'mt-2'}`}>
								<ul className={`navbar-nav me-auto mb-2 mb-lg-0 ${currUser ? 'justify-content-between' : 'justify-content-end'}` } style={{display: 'flex', alignItems: 'center'}}>
									{ currUser ?
										<>
										<div className="d-flex align-items-center gap-1">
											<button className="btn btn-dark" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{border: 'none', color: 'white', borderRadius: '10px', padding: '5px 15px'}}>Get the App</button>
											<span className="nav-item">
												<Link to="/sell-item">
													<button className="btn btn-dark" style={{border: 'none', color: 'white', borderRadius: '10px', padding: '5px 15px'}}>Sell Item</button>
												</Link>
											</span>
										</div>
											<div className="d-flex align-items-center gap-2">
												<Dropdown ref={dropdownRef} className="d-inline mx-2" autoclose="outside" id="inbox" onClick={fetchMessages}>
													<Dropdown.Toggle id="dropdown-autoclose-outside">
														<div className="d-flex align-items-end" autoclose="outside">
															<span className="nav-item">
																<div to="" className="mx-2 d-flex flex-column cursor-pointer pointer align-items-center" style={{position: 'relative'}}>
																	<div className="d-flex" style={{gap: '4px'}}>
																		<img alt="inbox icon" src={theme === "dark" ? InboxIcon : InboxIcon}  style={{height: '25px', width: '25px'}}/>
																		{ unread &&
																			<div style={{height: '10px', width: '10px', borderRadius: '50%', background: 'red', position: 'absolute', right: '-5px'}}></div>
																		}
																	</div>
																	<span style={{fontSize: '14px', color: 'white'}}>Chats</span>
																</div>
															</span>
															{/* <img src={downArrow} style={{height: '16px', width: '16px'}} alt="down-arrow" /> */}
														</div>
													</Dropdown.Toggle>

													<Dropdown.Menu id="dropdown-menu" align="end" style={{width: '400px',right: 0, left: 'auto'}}>
														<ul class="nav nav-pills mb-3 d-flex justify-content-around" id="pills-tab" role="tablist">
															<li class="nav-item" role="presentation">
																<div class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Messages</div>
															</li>
															
															<li class="nav-item" role="presentation">
																<div class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Notifications</div>
															</li>
														</ul>
														<div class="tab-content" id="pills-tabContent">
															<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
																{
																	messageLoader && <div class="spinner-border" role="status">
																		<span class="visually-hidden">Loading...</span>
																	</div>
																}
																{
																	inboxes.length === 0 ? <span>No Messages</span> : <div>
																		{
																			inboxes && inboxes.map(function(inbox) {
																				return <Link onClick={()=> hideDropdown(inbox.id)} to={`/inbox/${inbox.id}/messages`} style={{textDecoration: 'none'}} className="d-flex justify-content-between align-items-start gap-2 border-bottom p-2 hover-bg-gray" key={inbox.id}>
																					<img style={{height: '50px', width: '50px', borderRadius: '50%'}} src={inbox.other_user_avatar_url ? inbox.other_user_avatar_url : userAvatar} alt="user avatar" />
																					<div style={{flex: 1}}>
																						<div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
																							<strong>{inbox.other_user_name}</strong>
																							{ inbox.has_unread && <div style={{height: '10px', width: '10px', borderRadius: '50%', background: 'red'}}></div>}
																						</div>
																						<span>{Parser(inbox.message)}</span>
																						<br />
																						<span style={{color: 'gray', fontSize: '13px'}}>{inbox.message_created_at}</span>
																					</div>
																					<img style={{height: '50px', width: '50px'}} src={inbox.product_image} alt="product img" />
																				</Link>
																			})
																		}
																		{<Link to={"/inbox"} style={{textDecoration: 'none'}}><strong className="text-orange">View all messages</strong></Link> }
																	</div>
																}
															</div>
															<div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0">
																{ messageLoader && <div class="spinner-border" role="status">
																		<span class="visually-hidden">Loading...</span>
																	</div>
																}
																{
																	notifications.length === 0 ? <span>No Notifications</span> : <div>
																		{
																			notifications && notifications.map(function(notification) {
																				return <Link onClick={()=> hideDropdown(notification.id)} to={`/inbox/${notification.id}/messages`} style={{textDecoration: 'none'}} className="d-flex justify-content-between align-items-start gap-2 border-bottom p-2 hover-bg-gray" key={notification.id}>
																					<img style={{height: '50px', width: '50px', borderRadius: '50%'}} src={userAvatar} alt="user avatar" />
																					<div style={{flex: 1}}>
																						<div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
																							<strong>{notification.title}</strong>
																							{/* { inbox.has_unread && <div style={{height: '10px', width: '10px', borderRadius: '50%', background: 'red'}}></div>} */}
																						</div>
																						<span>{Parser(notification.content)}</span>
																						<br />
																						{/* <span style={{color: 'gray', fontSize: '13px'}}>{inbox.message_created_at}</span> */}
																					</div>
																					{/* <img style={{height: '50px', width: '50px'}} src={inbox.product_image} alt="product img" /> */}
																				</Link>
																			})
																		}
																		{<Link to={"/inbox"} style={{textDecoration: 'none'}}><strong className="text-orange">View all notifications</strong></Link> }
																	</div>
																}
															</div>
														</div>
														{/* <Dropdown.Item href="#">Menu Item</Dropdown.Item> */}
														{/* <Dropdown.Item href="#">Menu Item</Dropdown.Item> */}
														{/* <Dropdown.Item href="#">Menu Item</Dropdown.Item> */}
													</Dropdown.Menu>
												</Dropdown>
												<span className="nav-item">
													<Link to="/saved_products" className="mx-2 d-flex flex-column cursor-pointer pointer align-items-center" style={{textDecoration: 'none'}}>
														<img src={heartIcon}  style={{height: '25px', width: '25px'}}/>
														<span style={{fontSize: '14px', color: 'white'}}>Favorites</span>
													</Link>
												</span>
												<span className="nav-item">
													<Link to="/my-items" className="mx-2 d-flex flex-column cursor-pointer pointer align-items-center"  style={{textDecoration: 'none'}}>
														<img src={theme === "dark" ? tagIconWhite : tagIcon}  style={{height: '25px', width: '25px'}}/>
														<span style={{fontSize: '14px', color: 'white'}}>My Items</span>
													</Link>
												</span>
												<div className="dropdown">
													<div className="d-flex align-items-center flex-column" role="button" data-bs-toggle="dropdown" aria-expanded="false">
														<img src={ProfileIcon} style={{height: '30px', width: '30px'}}/>
														<span style={{fontSize: '14px', color: 'white'}}>Profile</span>
														{/* <img src={downArrow} style={{height: '16px', width: '16px'}}/> */}
													</div>
													<div className="dropdown-menu me-2 pull-right" style={{width: '288px',right: 0, left: 'auto'}}>
														<li>
															<div className="dropdown-item">
																<Link to={`/user/${currUser?.id}`} style={{textDecoration: 'underline', color: 'black', display: 'flex', alignItems: 'center'}}>
																	<div href="#" className="d-flex align-items-center gap-2 text-decoration-none">
																		<div>
																			<img src={currUser.avatar_url ? currUser.avatar_url : avatar} style={{height: '60px', width: '60px'}}/>
																		</div>
																		<div className="d-flex flex-column ms-2">
																			<strong style={{fontSize: '13px'}}>{currUser.email}</strong>
																			<span>View public profile</span>
																		</div>
																	</div>
																</Link>
															</div>
														</li>
														<li>
															<div className="nav-item">
															{
																currUser.is_admin &&
																<Link to="/admin/products">
																	<button style={{border: 'none', color: 'white', borderRadius: '10px', padding: '5px 15px', background: 'red'}}>Admin</button>
																</Link>
															}
															</div>
														</li>
														<li>
															<Link to="/account-settings" className="dropdown-item">Account Settings</Link>
														</li>
														<li>
															<Logout /> 
															{/* <a href="#" className="dropdown-item">Account Settings</a> */}
															{/* <%#= link_to "Log out", destroy_user_session_path, data: { turbo_method: :delete }, class: 'dropdown-item' %> */}
														</li>
													</div>
												</div>
											</div>
											{/* <span className="nav-item">
												<a href="#" className="nav-link">{currUser.email}</a>
											</span> */}
										</>
												: (
													<div className="d-flex gap-2">
														<button className="btn btn-dark" style={{border: 'none', color: 'white', borderRadius: '10px', padding: '5px 15px'}} onClick={onClickSignUp}>Singup</button>
														<button className="btn btn-dark" style={{border: 'none', color: 'white', borderRadius: '10px', padding: '5px 15px'}} onClick={onClickLogin}>Login</button>
													</div>
										)
									}
									{/* <select onChange={switchLanguage}>
										<option value="en">EN</option>
										<option value="bz">BZ</option>
									</select> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</nav>
			<div style={{position: 'relative', marginTop: '3px'}} className="home-search">
				<form onSubmit={submitSearchForm} style={{display: 'flex', flexDirection: 'column', width: '100%', gap: 8}} className="navbar-nav me-auto justify-between">
					<div className="d-flex  border-orange" style={{width: '100%'}}>
						<input type="text" placeholder="Search" ref={searchRef} className="search-input form-control" name="search" defaultValue={searchValue} style={{backgroundColor: "#FFF"}} />
						<button className="search-btn" style={{backgroundColor: "#FFF", border: 'none'}}>
							<img src={SearchIcon} alt="Search" style={{height: '32px', width: '32x'}}/>
						</button>
					</div>
				</form>
				<div className="d-flex align-items-center city-name gap-1" style={{position: 'absolute'}} onClick={showModal}>
					<SVG src={LocationIcon} className="fill-orange" />
					<strong className="text-orange underline">Deliver in: {cityName || "N/A"}</strong>
				</div>
			</div>
		</>
  )
}
export default OfferupNavbar